<template>
  <div class="paymoney">
    <ActionSheet
      :close-on-click-overlay="false"
      v-model="show"
      round
      title="设置支付密码"
      style="height:70%;overflow: hidden;"
      @cancel="onCancel"
    >
      <PasswordInput
        :value="pwd"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
        info="您暂未设置密码，设置 6 位数字"
      ></PasswordInput>

      <NumberKeyboard
        :show="show"
        theme="custom"
        v-model="pwd"
        maxlength="6"
        :safe-area-inset-bottom="true"
        close-button-text="完成"
        @blur="onblur"
        @input="onInput"
        @delete="onDelete"
      />
    </ActionSheet>
  </div>
</template>
<script>
import Setting from "@/api/setting";
import { PasswordInput, NumberKeyboard, ActionSheet } from "vant";
export default {
  components: { NumberKeyboard, ActionSheet, PasswordInput },
  data() {
    return {
      pwd: "",
      show: false,
      showKeyboard: true
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.pwd = "";
      }
    }
  },
  mounted() {},
  methods: {
    // 显示支付弹窗
    showPayToast() {
      this.show = true;
    },
    closePayToast() {
      this.show = false;
    },
    onCancel() {
      this.show = false;
      // Toast("取消");
    },
    onInput(value) {
      this.pwd = (this.pwd + value).slice(0, 6);
    },
    onDelete() {
      this.pwd = this.pwd.slice(0, this.pwd.length - 1);
    },
    async onblur() {
      //修改 支付密码密码
      var value = this.pwd;
      if (value.length == 6) {
        try {
          const res = await Setting.EditPayPassword(value);
          if (res.code == 200) {
            this.$toast("密码设置成功");
            this.show = false;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$toast("请输入长度6位的密码");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.paymoney {
  width: 100%;
  height: auto;
  .mima {
    position: relative;
    width: 100%;
    height: 30px;
    z-index: 3000;
    display: inline-block;
    line-height: 30px;
    font-size: 14px;
    padding-left: 20px;
  }
}
</style>
