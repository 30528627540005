<template>
  <div class="orderPay">
    <popup v-model="showpay" position="bottom" :style="{ height: '55%' }">
      <div class="pay-way">
        <div class="pay-title">
          <span>支付方式</span>
        </div>
        <radio-group v-model="radio" >
          <cell-group>
            <div v-for="(item, index) in payTypeArr" :key="index">
              <template v-if="balanceMaybeSave && item.payment_code == 'balance'">
                <cell clickable title="余额支付" is-link arrow-direction="down">
                  <template slot="title">
                    <!-- <span class="iconfont iconyue" style="color: #ee6515;" slot="icon"></span> -->
                    余额支付
                  </template>
                </cell>
                <!-- 会员卡余额支付 -->
                <cell
                  clickable
                  @click="radio = '1', setCardDis('balance', inputPrice)"
                  v-if="Number(allInfor.wallet.balance)>=Number(nowPayPrice)"
                >
                  <template slot="title">
                    <span class="iconfont iconhuiyuanqia" style="color: #ee6515;"></span>
                    <span class="custom-title">会员卡支付(剩余{{allInfor.wallet.balance}}元)</span>
                  </template>
                  <radio slot="right-icon" name="1" />
                </cell>
                <!-- 油卡支付 -->
                <template>
                  <div v-for="(oilPayitem, oilPayidx) in CardList" :key="oilPayidx + 'a'">
                    <cell
                      clickable
                      @click="radio = 'oil' + oilPayitem.card.name, nowOilIdx = oilPayidx,
                      setCardDis('card_balance', inputPrice, oilPayitem.id)"
                      v-if="Number(oilPayitem.balance)>=Number(nowPayPrice)"
                    >
                      <template slot="title">
                        <span class="iconfont iconjiayouka" style="color: #F5A844;"></span>
                        <span class="custom-title">{{ oilPayitem.card.name }}</span>
                        (剩余{{oilPayitem.balance}}元)
                      </template>
                      <radio slot="right-icon" :name=" 'oil' + oilPayitem.card.name" />
                    </cell>
                  </div>
                </template>
              </template>

              <!-- 储值支付 -->
              <template
                v-else-if="!balanceMaybeSave && item.payment_code == 'balance' && savePayShow"
              >
                <cell clickable>
                  <template slot="title">
                    <span class="iconfont iconchuzhiqia" style="color: red;"></span>
                    <span class="custom-title">储值支付</span>
                  </template>
                  <checkbox
                    v-model="selectSave"
                    @click="radio = '4'"
                    style="justify-content: flex-end"
                  ></checkbox>
                </cell>

                <div class="storageCont">
                  <div class="storageTabs">
                    <div
                      class="tab_title"
                      :class="showCont == 0?'tab_line':''"
                      v-if="recharge_data_stats"
                      @click="changeSave(0)"
                    >
                      <p>会员卡</p>
                      <p>(&yen;{{allInfor.wallet.balance}})</p>
                    </div>
                    <template v-for="(card_oil, card_index) in CardList">
                      <div
                        :key="card_index"
                        class="tab_title"
                        :class="showCont == card_index+1?'tab_line':''"
                        @click="changeSave(card_index+1)"
                        v-if="card_oil.usableData"
                      >
                        <p>{{card_oil.card.name}}</p>
                        <p>(&yen;{{card_oil.balance}})</p>
                      </div>
                    </template>
                  </div>

                  <div class="stored-value" v-if="recharge_data_stats&&showCont==0">
                    <swiper :options="swiperOption" v-if="recharge_state&&recharge_data.length > 0">
                      <swiperSlide
                        v-for="(vipitem, vipidx) in allInfor.recharge_set.rule"
                        class="discounts-item"
                        :key="vipidx"
                        v-show="Number(vipitem.charge_money) >= Number(nowPayPrice)? true: false"
                      >
                        <div
                          @click="setSwiper(vipitem, vipidx, 0)"
                          :class="[swiperIdx == vipidx ? 'sw-active' : '','discounts-item-con']"
                        >
                          <Tag
                            :type="swiperIdx == vipidx ? 'success' : ''"
                            class="discounts-item-con-check"
                          >
                            <span style="font-size:10px" class="iconfont iconduigou"></span>
                          </Tag>
                          <div>
                            <span>充</span>
                            <span>{{ vipitem.charge_money }}元</span>
                          </div>
                          <p v-if="vipitem.money_status == 1">送{{ vipitem.money }}元</p>
                        </div>
                      </swiperSlide>
                    </swiper>
                  </div>

                  <template v-for="(card_oil, card_index) in CardList">
                    <div
                      class="stored-value"
                      :key="card_index"
                      v-if="showCont == card_index+1&&card_oil.usableData"
                    >
                      <swiper :options="swiperOption">
                        <swiperSlide
                          v-for="(card_oilItem, card_oilidx) in card_oil.rechargeData.rule"
                          class="discounts-item"
                          :key="card_oilidx"
                          v-show="Number(card_oilItem.charge_money) >= Number(nowPayPrice)? true: false"
                        >
                          <div
                            @click="setSwiper(card_oilItem, card_oilidx, 1)"
                            :class="[swiperIdx == card_oilidx ? 'sw-active' : '','discounts-item-con']"
                          >
                            <Tag
                              :type="swiperIdx == card_oilidx ? 'success' : ''"
                              class="discounts-item-con-check"
                            >
                              <span style="font-size:10px" class="iconfont iconduigou"></span>
                            </Tag>
                            <div>
                              <span>充</span>
                              <span>{{ card_oilItem.charge_money }}元</span>
                            </div>
                            <p v-if="card_oilItem.money_status == 1">送{{ card_oilItem.money }}元</p>
                          </div>
                        </swiperSlide>
                      </swiper>
                    </div>
                  </template>
                </div>

                <template v-if="giftsObject">
                  <div class="stored-value">
                    <div class="stored-value-gifts">
                      <div class="stored-value-gifts-opt">
                        <p class="marginBtm">
                          <span class="iconfont iconchuzhiqia" style="color:#ff9900"></span>
                          &nbsp;储值到账{{Number(giftsObject.charge_money) + Number(giftsObject.money_status ==1?giftsObject.money:'')}}元
                        </p>
                        <p v-if="giftsObject.point_status == 1" class="marginBtm">
                          <span class="iconfont iconjifen3" style="color:#ff9900"></span>
                          &nbsp;充值赠送{{ giftsObject.point }}积分
                        </p>
                        <template v-if="giftsObject.coupon_status == 1">
                          <p>
                            <span class="iconfont iconyouhuijuan" style="color:#ff9900"></span>&nbsp;充值送券
                          </p>
                          <p
                            v-for="(item, index) in giftsObject.coupon_data"
                            :key="index"
                            class="coupon-option"
                          >
                            <span>{{ item.title }}</span>×1
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="margin-Btm" v-if="recharge_dis">
                      <span>充值优惠 <span>{{recharge_dis}} </span>元</span>
                    </div>
                    <div class="margin-Btm">
                      <span>充值后自动买单,买单后余额 <span>{{ savePrice }}</span> 元 </span>
                    </div>
                  </div>
                </template>
              </template>

              <template v-if="!selectSave">
                <!-- 微信支付 -->
                <cell
                  clickable
                  @click="radio = '2', setCardDis('single_wxpay', inputPrice)"
                  v-if="isWeixin_status == true &&item.payment_code == 'single_wxpay'"
                >
                  <template slot="title">
                    <span class="iconfont iconweixin1" style="color: #05c506;"></span>
                    <span class="custom-title">{{ item.name }}</span>
                  </template>
                  <radio slot="right-icon" name="2" />
                </cell>
                <!-- 支付宝支付 -->
                <cell
                  clickable
                  @click="radio = '3', setCardDis('alipay', inputPrice)"
                  v-if="isWeixin_status == false && item.payment_code == 'alipay'"
                >
                  <template slot="title">
                    <span class="iconfont iconumidd17" style="color: #169ADD;"></span>
                    <span class="custom-title">{{ item.name }}</span>
                  </template>
                  <radio slot="right-icon" name="3" />
                </cell>
                <!-- 银联支付 -->
                <cell clickable @click="radio = '5',setCardDis('unionpay', inputPrice)" v-if="item.payment_code == 'unionpay'">
                  <template slot="title">
                    <template v-if="isWeixin_status">
                      <span class="iconfont iconweixin1" style="color: #05c506;"></span>
                      <span class="custom-title">微信支付</span>
                    </template>
                    <template v-else>
                      <span class="iconfont iconumidd17" style="color: #169ADD;"></span>
                      <span class="custom-title">支付宝支付</span>
                    </template>
                  </template>
                  <radio slot="right-icon" name="5" />
                </cell>
                <cell clickable @click="radio = '14',setCardDis('fuiou', inputPrice)" v-if="item.payment_code == 'fuiou'">
                  <template slot="title">
                    <template v-if="isWeixin_status">
                      <span class="iconfont iconweixin1" style="color: #05c506;"></span>
                      <span class="custom-title">微信支付</span>
                    </template>
                    <template v-else>
                      <span class="iconfont iconumidd17" style="color: #169ADD;"></span>
                      <span class="custom-title">支付宝支付</span>
                    </template>
                  </template>
                  <radio slot="right-icon" name="14" />
                </cell>
                <cell clickable @click="radio = '18',setCardDis('normal_allinpay', inputPrice)" v-if="item.payment_code == 'normal_allinpay'">
                  <template slot="title">
                    <template v-if="isWeixin_status">
                      <span class="iconfont iconweixin1" style="color: #05c506;"></span>
                      <span class="custom-title">微信支付</span>
                    </template>
                    <template v-else>
                      <span class="iconfont iconumidd17" style="color: #169ADD;"></span>
                      <span class="custom-title">支付宝支付</span>
                    </template>
                  </template>
                  <radio slot="right-icon" name="18" />
                </cell>
                <!--微信支付-->
                <cell clickable @click="radio = '10', setCardDis('wxpay', inputPrice)" v-if="item.payment_code == 'wxpay'">
                  <template slot="title">
                    <template>
                      <span class="iconfont iconweixin1" style="color: #05c506;"></span>
                      <span class="custom-title">微信支付</span>
                    </template>
                  </template>
                  <radio slot="right-icon" name="10" />
                </cell>           
                <!-- 现金支付 -->
                <cell clickable @click="radio = '6',setCardDis('cash', inputPrice)" v-if="item.payment_code == 'cash'">
                  <template slot="title">
                    <span class="iconfont iconxianjin" style="color: #F5A844;"></span>
                    <span class="custom-title">{{ item.name }}</span>
                  </template>
                  <radio slot="right-icon" name="6" />
                </cell>
              </template>
            </div>
            <!-- 车队卡支付 -->
            <div v-for="(item, index) in TeamCardsList" :key="index + 'team'">
              <cell
                clickable
                @click="radio = 'car' + item.id, newCarIdx = index, setCardDis('team_balance', inputPrice, item.id)"
                v-if="Number(item.balance)>=Number(nowPayPrice)"
              >
                <template slot="title">
                  <span class="iconfont iconjiayouka" style="color: #F5A844;"></span>
                  <span
                    class="custom-title"
                    v-if="item.parent_id == 0"
                  >{{item.team.name}}车队主卡-{{ item.card.name }}</span>
                  <span class="custom-title" v-else>{{item.team.name}}车队子卡-{{ item.card.name }}</span>
                  (剩余{{item.balance}}元)
                </template>
                <radio slot="right-icon" :name="'car' + item.id" />
              </cell>
            </div>
            <!-- 亲属卡支付 -->
            <div v-for="(item, index) in familyCardList" :key="index + 'qsk'">
              <cell
                clickable
                @click="radio = 'qsk' + item.id, familyCardIdx = index, setCardDis('family_balance', inputPrice, item.id)"
                v-if="Number(item.balance)>=Number(nowPayPrice)"
              >
                <template slot="title">
                  <span class="iconfont iconjiayouka" style="color: #F5A844;"></span>
                  <span class="custom-title">{{item.real_name}}-亲属卡-{{ item.account_name }}</span>
                  (剩余{{item.balance}}元)
                </template>
                <radio slot="right-icon" :name="'qsk' + item.id" />
              </cell>
            </div>
          </cell-group>
        </radio-group>
      </div>
      <!-- 正常支付 -->
      <div class="pay-operation" v-if="!selectSave">
        <div class="pay-price">
          <p style="color: #fff;">
            ￥
            <span class="price-font">{{ nowPayPrice }}</span>
          </p>
          <span>已优惠￥{{ nowDisPrice }}</span>
        </div>
        <Button block class="submit-pay" @click.self="submitOrder">确认支付</Button>
      </div>
      <!--储值支付-->
      <div class="pay-operation" v-else>
        <div class="pay-price">
          <p style="color: #fff;">
            ￥
            <span class="price-font">{{ Topuppayment }}</span>
          </p>
          <span>已优惠￥{{ nowDisPrice }}</span>
        </div>
        <div class="operation">
          <div
            v-if="isWeixin_status"
            class="Other-pay wx_pay"
            @click="czCreateOrder(newPay)"
          >微信支付</div>
          <Button v-else class="submit-pay zfb_pay" @click="czCreateOrder('unionpay')">支付宝支付</Button>
        </div>
      </div>
    </popup>
    <!-- 支付模块 -->
    <PayMoney
      v-if="payModel"
      ref="pay"
      :passType="passType"
      :paytype="pay_type"
      :money="String(nowPayPrice)"
    ></PayMoney>
    <setPassword ref="setPassword"></setPassword>
  </div>
</template>

<script>
import setPassword from "./payChild/setPassword";
import "swiper/dist/css/swiper.css";
import PayMoney from "@/components/public/PayMoney";
import Setting from "@/api/setting";
import CreditShop from "@/api/creditshop";
import { wxPayTwo } from '@/utils/wxpay';
import { aliPay } from '@/utils/alipay';
import { goWxConfigJsApi } from "@/utils/wxpay";
import order from "@/api/order";
import { mapGetters } from "vuex";
import { setMoney, getSystemEnv } from "@/utils/tools"; 
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Cell, CellGroup, Checkbox, RadioGroup, Radio, Popup, Tag, Button } from "vant";
export default {
  components: {
    Cell,
    CellGroup,
    RadioGroup,
    Radio,
    Popup,
    PayMoney,
    Tag,
    Checkbox,
    swiper,
    swiperSlide,
    setPassword,
    Button
  },
  props: [
    "receipt_id",
    "inputPrice",
    "couponIds",
    "nowOilInfor",
    "allInfor",
    "payPrice",
    "discountsPrice",
    "deductIntegral",
    "ruleAmount",
    "qrcode_id",
    "room_id",
    "recharge_state",
    "useNum",
    "bill_status",
    "now_UserDis"
  ],
  computed: {
    ...mapGetters(['initDiscountArr','disAllMoney']),
  },
  data() {
    return {
      showCont: 0,
      saveActive: "0",
      activeName: ["1"],
      balanceArr: ['balance', 'card_balance', 'team_balance', 'family_balance'],
      nowPayType: "", // 支付方式
      nowPayPrice: this.payPrice, // 优惠后金额
      nowDisPrice: this.discountsPrice, // 总优惠（油卡 、会员卡 、车队卡）
      swiperOption: {
        observer: true,
        observeParents: true,
        direction: "horizontal",
        slidesPerView: 3,
        slidesPerGroup: 3,
        // width: 110,
        loop: false,
        speed: 1000,
        preventClicks: false,
        autoplay: false
      },
      showpay: false,
      payTypeArr: [], // 支付方式
      pay_type: null, // 余额、油卡支付类型
      selectSave: false, // 选中储值支付
      balanceMaybeSave: true, // true 余额，false储值 选择支付方式
      Topuppayment: "", //储值支付金额
      payClass: true, // 正常支付 false 储值只发
      savePayShow: false,
      selectidx: "", // 选择设备索引
      ifData: false,
      checked: false,
      cash_init: 0,
      radio: this.isWeixin_status ? '10':'5',
      now_tabIdx: 0,
      // room_id: "",
      // 优惠券
      newPay: "wxpay",
      payModel: true, // 支付
      ifStorage: false, // 是否存储支付
      giftsObject: "", // 赠送选项
      savePrice: "", //储值之后剩余金额
      swiperIdx: -1,
      saveKey: "", // 储值key
      is_used_num: 0,
      if_toLinkPay: false, // 是否使用银联支付
      return_url: "", //银联支付跳转链接
      CardList: [], // 油卡列表
      nowOilIdx: "", // 选中油卡index
      oil_password: "", // 支付时密码
      account_id: "", //账户id（油卡id,车队卡Id)
      useOilCard: false, // 是否使用油卡
      upIntegral: "", // 使用积分
      recharge_data: [], // 充值选项
      recharge_dis: "", // 充值优惠金额
      recharge_data_stats: false,
      TeamCardsList: [],
      newCarIdx: "", // 当前选中车队卡索引
      teamCardId: "", // 车队卡ID
      familyCardList: [],
      familyCardIdx: "",
      passType: "", // 支付类型
      btnState: true
    };
  },
  watch: {
    payPrice(value) {
      this.nowPayPrice = value; 
    },
    discountsPrice(value) {
      this.nowDisPrice = value; 
    },
    showpay(val) {
      if (!val) {
        this.selectSave = false;
        this.radio = this.isWeixin_status ? '10':'5';
        this.activeName = ["0"];
        this.CardList = [];
        this.recharge_data = [];
        this.account_id = "";
        this.recharge_id = "";
      } else {
        // this.getPaytype(); // 获取支付方式
        this.getCardList(); // 获取油卡列表
        this.isShowStorage(); // 获取储值列表
        this.getTeamCard(); // 获取车队卡
        this.Pay_family_card(); // 获取亲属卡
      }
    },
    disAllMoney: {
      handler: function(val) {
        this.$parent.discountsPrice = val.dis_money;
        this.$parent.payPrice = val.end_money;
      },
      deep: true
    },
    async radio(val) {
      let typeArr = ['car', 'qsk', 'oil'];
      const now_idx = this.$store.state.discountInfo.now_UserDisKey.toString();
      if (val == "1" || typeArr.indexOf(val.substring(0, 3)) >= 0) {
        let arr = this.initDiscountArr.concat(now_idx);
        await this.$store.dispatch('noUsedDisArr', {arr, money: this.inputPrice, type: 2});
      } else {
        let info = this.disAllMoney;
        const res = setMoney(this.initDiscountArr.concat(now_idx), this.inputPrice, this.disAllMoney);
        if (res) {
          info.dis_money = res.dis_money;
          info.end_money = res.end_money;
          this.$store.commit('updateAllDisMoney', info);
        }
      }
      switch (val) {
        case "4":
          this.ifStorage = true;
          if (!this.Topuppayment) {
            this.Topuppayment = this.payPrice;
          }
          break;
        case "6":
          // 现金凑整
          this.ifStorage = false;
          if (localStorage.getItem("app_id") == "402") {
            this.$dialog
              .confirm({
                title: "是否使用现金支付",
                message: "使用现金支付将不会使用优惠，是否确认"
              })
              .then(() => {
                this.$parent.cashCancelDis();
              })
          }
          break;
        default:
          this.ifStorage = false;
          break;
      }
    },
    ifStorage(val) {
      if (!val) {
        this.clearSave();
      }
    },      
    //是否使用储值支付
    selectSave(val) {
      if (val == false) {
        this.clearSave();
      } else {
        this.radio = "4";
      }
    }
  },
  created() {
    goWxConfigJsApi("chooseWXPay");
    this.getPaytype();
    // 初始化配置
    this.initPaySetting(); 
    // 初始化优惠信息
    // this.$store.dispatch('noUsedDisArr', { a: "aaaa" });
  },
  methods: {
    // 现金支付是否取整数
    initPaySetting() {
      this.getAppConfig().then(arr => {
        for (let i in arr) {
          if (arr[i].name == "cash_int") {
            this.cash_init = arr[i].status;
          }
        }
      })
    },
    // 计算油卡优惠
    async setCardDis(type, money, account_id, recharge_id = "", idx = "", status = false) {
      try {
        const res = await order.DisPayAvailable(
          type,
          account_id ? account_id:this.account_id, 
          this.room_id, 
          this.nowOilInfor.goods_id, 
          money, 
          recharge_id,
          idx 
        );
        if(res.code == 200) {
          if (this.balanceArr.indexOf(type) > -1) {
            this.$store.state.discountInfo.recharge_dismoney = res.total_discount;
            const now_idx = this.$store.state.discountInfo.now_UserDisKey.toString();
            const user_num_money = this.giftsObject.charge_money;
            // 是否享受优惠
            const arr = this.initDiscountArr.concat(now_idx);
            await this.$store.dispatch('noUsedDisArr', {arr, money: this.inputPrice, type: 2});
            if (status) {
              this.recharge_dis = res.total_discount;
              this.Topuppayment = user_num_money;
              this.nowDisPrice = 0;
            } else {
              this.nowDisPrice = this.$formCode.plus(this.discountsPrice, res.total_discount);
              this.nowPayPrice = this.$formCode.round(this.$formCode.minus(this.inputPrice, this.nowDisPrice), 2).toString();
            }
          } else {
            if (type == "cash") {
              // 是否现金凑整
              if (this.cash_init) {
                this.nowPayPrice = Math.round(this.payPrice);
              } else {
                this.nowPayPrice = this.$formCode.round(this.payPrice, 2);
              }
              this.nowDisPrice = this.discountsPrice;
            } else {
              let now_pay_num = this.$formCode.minus(this.payPrice, res.total_discount);
              let sum_dis_num = this.$formCode.plus(this.discountsPrice, res.total_discount);
              this.nowPayPrice = this.$formCode.round(now_pay_num, 2);
              this.nowDisPrice = this.$formCode.round(sum_dis_num, 2);
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getPaytype() {
      // 获取支付方式
      try {
        const res = await CreditShop.getPaytype(getSystemEnv());
        this.payTypeArr = res.data;
        if (this.payTypeArr.length > 0) {
          this.payTypeArr.map(item => {
            if (item.is_default === 1) {
              switch (item.payment_code) {
                case "wxpay":
                  this.radio = "10";
                  break;
                case "unionpay":
                  this.radio = "5";
                  break;
                case "fuiou":
                  this.radio = "14";
                  break;
                case "alipay":
                  this.radio = "3";
                  break;
                case "normal_allinpay":
                  this.radio = "18";
                  break;
              }
              this.newPay = item.payment_code;
              return;
            }
          });
        } else {
          this.$toast("暂未设置支付方式");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async Pay_family_card() {
      try {
        const res = await CreditShop.Pay_family_card(
          this.$parent.inputPrice,
          "",
          this.allInfor.store_id,
          this.allInfor.room_id,
          this.nowOilInfor.goods.type_id,
          this.nowOilInfor.goods.id
        );
        if (res.code == 200) {
          this.familyCardList = res.data;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取车队卡
    async getTeamCard() {
      try {
        const res = await CreditShop.TeamCardsPay(
          this.nowOilInfor.goods.type_id,
          this.nowOilInfor.goods.id,
          this.room_id,
          this.$parent.inputPrice
        );
        if (res.code == 200) {
          this.TeamCardsList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 车队卡判断使用什么密码
    async judge_pass(id) {
      try {
        const res = await CreditShop.validate_pass(id);
        if (res.code == 200) {
          setTimeout(() => {
            this.passType = res.msg;
            this.$toast({
              message: res.msg,
              duration: 2000
            });
          }, 300);
          if (res.status == 1) {
            this.isPassword(3);
          } else {
            this.pay_type = 7;
            this.account_id = this.teamCardId;
            this.$refs.pay.showPayToast();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeSave(idx) {
      this.clearSave();
      this.showCont = idx;
    },
    clearSave() {
      this.giftsObject = "";
      this.swiperIdx = -1;
      this.recharge_id = "";
      this.account_id = "";
      this.Topuppayment = "";
    },
    // 如果余额大于付款金额为余额支付，否者是储值支付
    judgePayType(val) {
      console.log('如果余额大于付款金额为余额支付，否者是储值支付')
      var isoilscard = false;
      var showsavePay = false;
      if (this.CardList.length > 0) {
        if (this.CardList.filter(item => Number(item.balance >= Number(val))).length > 0) {
          isoilscard = true;
        }
      }
      if (Number(this.allInfor.wallet.balance) >= Number(val) || isoilscard) {
        // 判断显示余额还是储值
        this.balanceMaybeSave = true;
      } else {
        this.balanceMaybeSave = false;
      }
      for (let index = 0; index < this.CardList.length; index++) {
        const element = this.CardList[index];
        if (element.usableData) {
          showsavePay = true;
        }
      }
      if (showsavePay || this.recharge_data.length > 0) {
        //只要有一个储值项存在便显示
        this.savePayShow = true;
      } else {
        this.savePayShow = false;
      }
    },
    //判断储值选项是否显示
    isShowStorage() {
      if (!(this.allInfor.recharge_set instanceof Array)) {
        for (let i = 0; i < this.allInfor.recharge_set.rule.length; i++) {
          const element = this.allInfor.recharge_set.rule[i];
          if (Number(element.charge_money) >= Number(this.inputPrice)) {
            this.recharge_data.push(element);
          }
        }
        if (this.recharge_data.length > 0) {
          this.recharge_data_stats = true;
        } else {
          this.recharge_data_stats = false;
        }
      }
    },
    // 获取油卡列表
    async getCardList() {
      try {
        this.CardList = [];
        const res = await CreditShop.payOilsList(
          this.nowOilInfor.goods.type_id,
          this.nowOilInfor.goods.id,
          this.room_id
        );
        if (res.code == 200) {
          if (res.data.length > 0) {
            for (let i in res.data) {
              this.CardList.push(res.data[i]);
              if (Number(res.data[i].balance) <= Number(this.payPrice)) {
                // 判断剩余金额是否足够
                this.$set(this.CardList[i], "rechargeData", await this.getSetMoney(res.data[i].card.id));
                for (let index = 0; index < this.CardList[i].rechargeData.rule.length; index++) {
                  const element = this.CardList[i].rechargeData.rule[index];
                  if (Number(element.charge_money) > Number(this.payPrice)) {
                    this.$set(this.CardList[i], "usableData", true);
                    break;
                  }
                }
              }
            }
          }
          this.showCont = this.recharge_data_stats ? 0 : 1;
          // 判断是储值还是余额
          this.judgePayType(this.payPrice); 
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 油卡充值选项
    async getSetMoney(card_id) {
      try {
        const res = await CreditShop.storedInfor(1, card_id);
        if (res.code == 200 || res.code == 400) {
          if (res.data && res.data.rule.length > 0) {
            return res.data;
          } else {
            return [];
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确认订单
    submitOrder() {
      if (this.radio == 0) {
        this.$toast("请选择支付方式");
        return;
      }
      this.$toast.loading({
        message: "调起支付...",
        forbidClick: true,
        duration: 0
      });
      this.if_toLinkPay = false; //不使用银联
      this.return_url = ""; // 回转链接
      this.oil_password = ""; // 清空之前保存的密码
      this.useOilCard = false; // 不使用油卡支付
      if (this.radio.substring(0, 3) == "car") {
        // 车队卡
        this.teamCardId = this.radio.substring(3);
        this.$parent.cancelInvoice();
        this.judge_pass(this.teamCardId);
        this.$toast.clear();
      } else if (this.radio.substring(0, 3) == "qsk") {
        // 亲属卡
        this.$parent.cancelInvoice();
        this.$toast.clear();
        this.isPassword(2);
      } else {
        switch (this.radio) {
          case "1":
            this.$parent.cancelInvoice();
            this.balancePay();
            this.$toast.clear();
            break;
          case "2":
            this.ewmCreateOrder("single_wxpay");
            break;
          case "3":
            this.ewmCreateOrder("alipay");
            break;
          case "14":
            this.ewmCreateOrder("fuiou");
            break;
          case "18":
            this.ewmCreateOrder("normal_allinpay");
            break;
          case "5":
            this.if_toLinkPay = true;
            this.return_url = `https://jjtc.58zltc.com/pay_success?appid=${localStorage.getItem(
              "app_id"
            )}&`;
            this.ewmCreateOrder("unionpay");
            break;
          case "6": //现金支付
            this.ewmCreateOrder("cash");
            break;
          case "7": //通联支付
            this.ewmCreateOrder("allinpay");
            break;
          case "10": // 微信支付
            this.ewmCreateOrder("wxpay");
            break;  
          default:
            this.useOilCard = true; // 使用油卡
            this.$parent.cancelInvoice();
            this.OilCard_pay();
            break;
        }
      }
    },
    //提交订单 油卡支付
    OilCard_pay() {
      if (
        Number(this.CardList[this.nowOilIdx].balance) - Number(this.nowPayPrice) < 0
      ) {
        this.$toast(`余额不足剩余${this.CardList[this.nowOilIdx].balance}`);
      } else {
        this.$toast.clear();
        this.isPassword(1);
      }
    },
    //输入密码，确认油卡支付
    subOilCard_pay(pwb) {
      this.oil_password = pwb;
      this.ewmCreateOrder("card_balance");
    },
    //提交订单 余额支付
    balancePay() {
      if (Number(this.allInfor.wallet.balance) - Number(this.nowPayPrice) < 0) {
        this.$toast(`余额不足剩余${this.allInfor.wallet.balance}`);
      } else {
        this.$toast.clear();
        this.isPassword(0);
      }
    },
    // 判断是否设置交易密码
    async isPassword(type) {
      try {
        const res = await Setting.judgePayPass();
        if (res.code == 200) {
          switch (type) {
            case 0:
              this.pay_type = 4;
              break;
            case 1:
              this.account_id = this.CardList[this.nowOilIdx].id;
              this.pay_type = 6;
              break;
            case 2:
              this.account_id = this.familyCardList[this.familyCardIdx].id;
              this.pay_type = 8;
              break;
            case 3:
              this.pay_type = 7;
              this.account_id = this.teamCardId;
              break;
            default:
              break;
          }
          this.$refs.pay.showPayToast();
        } else {
          this.$refs.setPassword.showPayToast();
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 余额付款
    async oilBalancePay(pwd) {
      try {
        const res = await CreditShop.ewmCreateOrder(
          this.qrcode_id,
          // 73,
          this.room_id,
          this.nowOilInfor.id,
          this.inputPrice,
          "",
          0,
          "balance",
          this.disAllMoney.allow_point,
          pwd,
          "",
          this.couponIds,
          "",
          "",
          "",
          "",
          this.useNum,
          this.isWeixin_status ? "wx" : "h5",
          0,
          this.$store.state.discountInfo.now_disArr.join(',')
        );
        if (res.code == 200) {
          this.$toast(res.msg);
          this.$nextTick(() => {
            this.$router.push({
              path: "/pay_success",
              query: {
                ordersn: res.data.ordersn,
                type: 1
              }
            });
          });
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    //提交订单 微信支付，支付宝支付， 油卡支付
    async ewmCreateOrder(type) {
      try {
        const res = await CreditShop.ewmCreateOrder(
          this.qrcode_id, //二维码id
          this.room_id, // 门店id
          this.nowOilInfor.id, // 油枪id
          this.inputPrice, //输入金额
          "", // 留言
          this.ifStorage ? 1 : 0, // 储值支付
          type, // 支付类型
          this.disAllMoney.allow_point, // 积分
          this.oil_password, // 余额支付密码
          this.recharge_id, // 充值id
          this.couponIds, // 优惠券ID
          this.return_url, // 银联跳转
          this.receipt_id, // 发票
          this.account_id, // 账户id
          this.saveKey, // 充值项
          this.useNum,
          this.isWeixin_status ? "wx" : "h5",
          this.bill_status,
          this.$store.state.discountInfo.now_disArr.join(',')
        );
        if (res.code == 200) {
          this.$parent.cancelInvoice(); // 取消发票请求
          switch (type) {
            case "unionpay":
              window.location.href = `${
                res.data.url
              }&payTimestamp=${new Date().getTime()}`;
              break;
            case "wxpay":
              wxPayTwo(res.data)
              break;
            case "fuiou":
              if (this.isWeixin_status) {
                wxPayTwo(res.data)
              } else {
                // 支付宝支付
                await aliPay(res.data.targetOrderId);
              }
              break;
            case "alipay":
              if (this.isWeixin_status) {
                wxPayTwo(res.data)
              } else {
                // 支付宝支付
                await aliPay(res.data.targetOrderId);
              }
              break;
           case "normal_allinpay":
              if (this.isWeixin_status) {
                wxPayTwo(res.data)
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
            case "allinpay":
              location.href = res.data.url;
              break;
            case "card_balance":
              this.$nextTick(() => {
                this.$router.push({
                  path: "/pay_success",
                  query: {
                    ordersn: res.data.ordersn,
                    type: 1
                  }
                });
              });
              break;
            case "family_balance":
              this.$nextTick(() => {
                this.$router.push({
                  path: "/pay_success",
                  query: {
                    ordersn: res.data.ordersn,
                    type: 1
                  }
                });
              });
              break;
            case "team_balance":
              this.$nextTick(() => {
                this.$router.push({
                  path: "/pay_success",
                  query: {
                    ordersn: res.data.ordersn,
                    type: 1
                  }
                });
              });
              break;
            case "cash":
              // 判断是否现金支付
              this.$toast.clear();
              this.$dialog.alert({
                message: res.msg,
              }).then(() => {
                this.$router.push('/vip')
              });
              break;
            default:
              this.$toast.clear();
              this.$router.replace({
                path: "payewm",
                query: {
                  imgUrl: res.data.qrcode,
                  ordersn: res.data.ordersn,
                  money: res.data.money,
                  payType: type
                }
              });
              break;
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast("支付失败" + error);
      }
    },
    // 选择储值支付选项
    async setSwiper(item, idx, type) {
      let balance = null;
      this.swiperIdx = idx;
      this.giftsObject = item;
      let zsbalance = ""; // 赠送余额
      if (item.money_status == 1) {
        zsbalance = item.money;
      }
      if (this.selectSave == false) {
        this.selectSave = true;
      }
      // 判断是油卡充值支付还是会员卡储值支付
      if (type == 0) {
        this.recharge_id = this.allInfor.recharge_set.id;
        balance = this.allInfor.wallet.balance;
        await this.setCardDis("balance", this.inputPrice, "", this.recharge_id, idx, true);
      } else {
        this.recharge_id = this.CardList[this.showCont - 1].rechargeData.id;
        this.account_id = this.CardList[this.showCont - 1].id;
        balance = this.CardList[this.showCont - 1].balance;
        await this.setCardDis("card_balance", this.inputPrice, "", this.recharge_id, idx, true);
      }
      // 买单后余额
      let dis_1 = this.disAllMoney.dis_money;
      let sum_money = this.$formCode.plus(balance, item.charge_money, Number(zsbalance), this.recharge_dis, dis_1);
      this.savePrice = this.$formCode.round((sum_money - this.inputPrice), 2);
    },
    czCreateOrder(type) {
      this.$toast.loading({
        message: "调起支付...",
        forbidClick: true,
        duration: 0
      });
      // 充值支付
      if (this.swiperIdx >= 0) {
        this.if_toLinkPay = true;
        this.saveKey = this.swiperIdx;
        if (type == "unionpay") {
          this.return_url = `https://jjtc.58zltc.com/pay_success?appid=${localStorage.getItem(
            "app_id"
          )}&`;
        } else {
          this.return_url = "";
        }
        this.ewmCreateOrder(type);
      } else {
        //判断是否选择充值项
        this.$toast("请选择一项充值金额");
        this.recharge_id = "";
        this.account_id = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pay-way {
  border-radius: 8px;
  overflow: auto;
  height: calc(100% - 50px);
  // padding-bottom: 60px;
  .storageCont {
    .storageTabs {
      height: 44px;
      width: 100%;
      overflow-x: auto;
      display: flex;
      padding-left: 16px;
      border-bottom: 1px solid #ebedf0;
    }
  }
  .tab_title {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    p {
      font-size: 12px;
      height: 18px;
      line-height: 18px;
    }
    p:nth-child(2) {
      color: #ee0a24;
    }
  }
  .tab_line {
    position: relative;
  }
  .tab_line::after {
    position: absolute;
    width: 80%;
    bottom: 0;
    height: 2px;
    background: #ee0a24;
    content: "";
  }
  .stored-value {
    // height: 70px;
    padding: 5px 10px;
    .discounts-item {
      padding: 0 10px;
      width: 120px !important;

      .discounts-item-con {
        min-height: 50px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        padding: 8px 10px;
        background: #fdfdfd;
        border: 1px solid #dfdfdf;

        .discounts-item-con-check {
          position: absolute;
          left: 10px;
          top: 0;
        }
      }

      .sw-active {
        color: rgba(0, 149, 255, 1);
        background: rgba(238, 245, 255, 1);
        border: 1px solid rgba(0, 149, 255, 1);
      }
    }

    .margin-Btm {
      padding-left: 10px;
      padding-bottom: 8px;
      span > span {
        color: rgb(238, 10, 36);
      }
    }
    .submitPay {
      height: 50px;
      display: flex;
      .paymony {
        flex: 2;
        text-align: center;
        .mony {
          font-size: 18px;
        }
      }
      .submitStored {
        flex: 8;
        display: flex;
        span {
          margin-right: 5px;
        }
        .wxpay {
          flex: 1;
          margin-right: 5px;
        }
        .zfbpay {
          flex: 1;
        }
      }
    }
  }

  .nocogfig {
    text-align: center;
  }

  .stored-value-gifts {
    width: 96%;
    height: auto;
    background: antiquewhite;
    margin: 0 auto 10px;
    padding: 10px;
    font-size: 12px;
    .stored-value-gifts-opt {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    span {
      margin-right: 5px;
      font-size: 12px;
    }
    .marginBtm {
      margin-bottom: 3px;
    }
    .coupon-option {
      padding-left: 20px;
    }
  }
}

.iconjiayouka {
  font-size: 11px;
}
.pay-title {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.pay-operation {
  width: 100%;
  height: 50px;
  overflow: hidden;
  z-index: 6;
}

.pay-operation {
  width: 100%;
  height: 50px;
  overflow: hidden;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  background: #333335;

  .pay-price {
    color: #717172;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .price-font {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .submit-pay {
    width: 90px;
    height: 100%;
    color: #fff;
    font-weight: 560;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      139deg,
      rgba(124, 163, 249, 1) 0%,
      rgba(70, 106, 240, 1) 100%
    );
    z-index: 9999;
    border: none;
  }

  .Other-pay {
    background: #484848;
    color: #fff;
  }

  .zfb_pay {
    width: 130px;
    background: #199bdd;
    border: none;
  }

  .wx_pay {
    width: 100px;
    height: 100%;
    font-weight: 560;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #05c506;
  }
}
</style>

<style lang="scss">
.pay-way {
  .van-collapse-item__content {
    padding: 0 !important;
  }
  .van-tab {
    flex: inherit !important;
    padding: 0 16px;
  }
}
</style>